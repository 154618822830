export const VideotestimonialData=[
    {
        link:"https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/Video_reviews%2FVID-20210529-WA0001.mp4?alt=media&token=2697bd48-56fb-4a69-b76d-5f61843e82cd"
    },
    {
        link:"https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/Video_reviews%2FVID-20210617-WA0017.mp4?alt=media&token=48c6f44c-7a93-42ff-9100-8c1ed0d4f44c"
    },
    {
        link:"https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/Video_reviews%2FVID-20210619-WA0005.mp4?alt=media&token=5da09937-6139-402e-83c5-6d3bed7ec174"
    },
    {
        link:"https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/Video_reviews%2FVID-20210625-WA0010.mp4?alt=media&token=684deeec-7829-47e1-b8b8-51bf044dfbb9"
    },
    {
        link:"https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/Video_reviews%2FVID-20210528-WA0000.mp4?alt=media&token=46662cc2-b9a0-4e68-af80-65c483faa741"
    },
] 