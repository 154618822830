export const NUmberData=[

    {"Number": "7 999999 202", "type": "Hexa 9"},
    {"Number": "7 999999 232", "type": "Hexa 9"},
    {"Number": "7 999999 343", "type": "Hexa 9"},
    {"Number": "7 999999 383", "type": "Hexa 9"},
    {"Number": "7 999999 636", "type": "Hexa 9"},
    {"Number": "7 999999 192", "type": "hexa 9"},
    {"Number": "7 999999 196", "type": "hexa 9"},
    {"Number": "7 999999 295", "type": "hexa 9"},
    {"Number": "7 999999 391", "type": "hexa 9"},
    {"Number": "7 999999 392", "type": "hexa 9"},
    {"Number": "7 999999 394", "type": "hexa 9"},
    {"Number": "7 999999 395", "type": "hexa 9"},
    {"Number": "7 999999 396", "type": "hexa 9"},
    {"Number": "96739 22222", "type": "Penta x"},
    {"Number": "93573 22222", "type": "Penta x"},
    {"Number": "96421 33333", "type": "Penta x"},
    {"Number": "96371 44444", "type": "Penta x"},
    {"Number": "96376 44444", "type": "Penta x"},
    {"Number": "96372 44444", "type": "Penta x"},
    {"Number": "96379 44444", "type": "Penta x"},
    {"Number": "96738 44444", "type": "Penta x"},
    {"Number": "96735 44444", "type": "Penta x"},
    {"Number": "91670 44444", "type": "Penta x"},
    {"Number": "96739 44444", "type": "Penta x"},
    {"Number": "91689 44444", "type": "Penta x"},
    {"Number": "96375 44444", "type": "Penta x"},
    {"Number": "96371 66666", "type": "Penta x"},
    {"Number": "91673 88888", "type": "Penta x"},
    {"Number": "91675 88888", "type": "Penta x"},
    {
        "Number": "7069 28 1111",
        "type": "aaaa"
       },
       {
        "Number": "7069 42 1111",
        "type": "aaaa"
       },
       {
        "Number": "7069 48 1111",
        "type": "aaaa"
       },
       {
        "Number": "7069 58 1111",
        "type": "aaaa"
       },
       {
        "Number": "7434 98 1111",
        "type": "aaaa"
       },
       {
        "Number": "7434 93 1111",
        "type": "aaaa"
       },
     
     
]