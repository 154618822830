export const movingCarouselData = [
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204902.webp?alt=media&token=e8999181-1402-48e1-8e19-1a3c7959ae4b"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204911.webp?alt=media&token=ad3d1d6e-538c-4e23-abb8-38fcf8a1c501"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204915.webp?alt=media&token=58d742fa-4a6a-4cee-9d8a-fe5acd62c6c0"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204917.webp?alt=media&token=ab00e02e-fa21-4500-9feb-021d6a579bd4"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204919.webp?alt=media&token=61bf9ee8-510a-47ac-8c89-f5adf307768d"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204921.webp?alt=media&token=be48e7ac-37a2-48e0-963d-96a83ee2f26c"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204924.webp?alt=media&token=f0ca777b-07b1-48bd-bd47-14537c06ad2f"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204926.webp?alt=media&token=61ed2921-77b5-43b1-9d85-cdc66a01106b"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204929.webp?alt=media&token=d8cbadec-8891-4b0f-accd-77b08b6f78d7"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204931.webp?alt=media&token=d70347da-e928-4fb0-90b6-996313a9e9ca"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204934.webp?alt=media&token=eba75ee6-b8c1-4724-aaf1-f9a28fbc2e84"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204936.webp?alt=media&token=48ea21b1-4961-49fe-b9c2-a0ea64538d34"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204938.webp?alt=media&token=fc98f9c0-667f-4ead-b37a-88f2cd5cedb2"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204940.webp?alt=media&token=6ec93e0c-347d-448f-b282-dd5cd5f92c18"
   },
   {
      img: "https://firebasestorage.googleapis.com/v0/b/rajvip-numbers.appspot.com/o/reviews_ss%2FScreenshot_20240113-204943.webp?alt=media&token=ea975110-41a4-40bc-94c7-2e597e7464a1"
   }










];
