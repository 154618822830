import React from 'react'
import MovingCards from '../components/MovingCards'

function Reviews() {
    
    return(
 
<div className='text-center '>

    
<h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl ">Trusted And Transparent</h1>
<p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">Here at Raj Vip we Believe we are buyinhg Trust and Selling Goodwill to you Guys with our Top Notch VIP Numbers.</p>



<MovingCards/>


</div>
)}

export default Reviews

